@import '/app/styles/variables';
@import '~@andes/common/index';

.error {
    height: 100%;
}

.#{$error-prefix}-container {
    height: 100%;
}
