@import '~@andes/common/index';

body {
  background-color: $andes-gray-070-solid;
}

main {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Proxima Nova", -apple-system, "Helvetica Neue", Helvetica, Roboto,
    Arial, sans-serif;
}
